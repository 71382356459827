import * as Yup from 'yup';
import { OutgoingDocument } from '@models';

export function useValidationSchema() {
  return Yup.object<OutgoingDocument>().shape({
    summary: Yup.string().nullable().required(),
  }) as Yup.Schema<OutgoingDocument>;
}

export function useCreateValidationSchema() {
  return Yup.object<OutgoingDocument>().shape({
    summary: Yup.string().nullable().required(),
    decisionForm: Yup.string().nullable().when('decision', {
      is: true,
      then: Yup.string().required(),
    }),
    decisionFile: Yup.string().nullable().when('decision', {
      is: true,
      then: Yup.string().required(),
    }),
  }) as Yup.Schema<OutgoingDocument>;
}
