import { merge } from 'lodash';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DetailToolbarBtnName,
  EmptyComponent,
  EvidenceProps,
  FormPanel,
  getItemFactory,
  updateItemFactory,
} from '@eas/common-web';
import { useDatedEvidence } from '@composite/evidence/dated-evidence';
import { ViewingRequest } from '@models';
import { EntityName, EvidenceApiUrl } from '@enums';
import { ActionBar } from './viewing-request-actionbar';
import { postProcess } from './viewing-request-api';
import {
  PhysicalViewingDetails,
  useViewingRequestFields,
} from './viewing-request-fields';
import { useValidationSchema } from './viewing-request-schema';

export function Fields() {
  const ViewingRequestFields = useViewingRequestFields();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__VIEWING_REQUESTS__DETAIL__REQUEST_PANEL_TITLE"
            defaultMessage="Žádost"
          />
        }
      >
        <ViewingRequestFields />
      </FormPanel>
      <PhysicalViewingDetails />
    </>
  );
}

export function useViewingRequests({
  evidenceProps,
}: {
  evidenceProps?: Partial<EvidenceProps<ViewingRequest>>;
}) {
  const intl = useIntl();

  const columns = evidenceProps?.tableProps?.columns ?? [];
  const validationSchema = useValidationSchema();

  const props = merge(
    {
      identifier: 'VIEWING_REQUESTS',
      apiProps: {
        url: EvidenceApiUrl.VIEWING_REQUEST,
        getItem: getItemFactory({
          postProcess,
        }),
        updateItem: updateItemFactory({
          postProcess,
        }),
      },
      tableProps: {
        columns,
        tableName: intl.formatMessage({
          id: 'ES__VIEWING_REQUESTS__TABLE__TITLE',
          defaultMessage: 'Žádosti o nahlížení',
        }),
        defaultSorts: [],
        showReportButton: false,
      },
      detailProps: {
        entityName: EntityName.VIEWING_REQUEST,
        FieldsComponent: Fields,
        GeneralFieldsComponent: EmptyComponent,
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ES__VIEWING_REQUESTS__DETAIL__TITLE',
            defaultMessage: 'Žádost o nahlížení',
          }),
          showBtn: (btn: DetailToolbarBtnName) => btn !== 'NEW',
          ActionBar,
        },
        validationSchema,
      },
    },
    evidenceProps
  );

  const evidenceBag = useDatedEvidence<ViewingRequest>(props);

  return { evidenceBag };
}
