import { getErrorMessage } from '@utils/error-message';
import React, { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  AbortableFetch,
  DetailContext,
  DetailHandle,
  FilesContext,
  PrimaryDetailActionbarButton,
  SnackbarContext,
  SnackbarVariant,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { ErrorObject, ViewingRequest } from '@models';
import { EvidenceApiUrl } from '@enums';

export function PrintPhysicalDetailsButton() {
  const intl = useIntl();
  const fetch = useRef<AbortableFetch | null>(null);

  const { getFileUrl } = useContext(FilesContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source } = useContext<DetailHandle<ViewingRequest>>(DetailContext);

  const callApi = useEventCallback(() =>
    abortableFetch(
      `${EvidenceApiUrl.ES_FILES}/viewing/${source.data?.id}/physical-viewing-info`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  return (
    <PrimaryDetailActionbarButton
      label={intl.formatMessage({
        id: 'ES__VIEWING_REQUEST__PRINT_DETAILS__TITLE',
        defaultMessage: 'Tisk detailů fyzického nahlížení',
      })}
      onClick={async () => {
        try {
          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = callApi();

          let message = intl.formatMessage({
            id: 'FILE_COVER__REQUEST_SUCCESSFULLY_SENT',
            defaultMessage: 'Dokument se generuje, čekejte prosím.',
          });

          const response = await fetch.current.json();

          const a = document.createElement('a');
          a.href = getFileUrl(response.id);
          a.download = response.name || 'obal spisu.pdf';

          document.body.appendChild(a); // Required for FF
          a.click();
          a.remove();

          message = intl.formatMessage({
            id: 'FILE_COVER__REQUEST_SUCCESSFULL',
            defaultMessage: 'Akce byla úspěšně vykonána.',
          });

          showSnackbar(message, SnackbarVariant.SUCCESS);
        } catch (e) {
          const err = e as ErrorObject<string>;
          const message = getErrorMessage(err);

          if (err.name !== 'AbortError') {
            showSnackbar(...message);
          }
        }
      }}
    />
  );
}
