import React from 'react';
import {FormattedMessage} from 'react-intl';
import {FormCheckbox, FormFileField, FormPanel, FormTextField} from '@eas/common-web';

export function DataBoxFields({
  display,
  disabled = false,
}: {
  display: 'IN_DOCUMENT' | 'IN_DISPATCH';
  disabled?: boolean;
}) {
  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__DOCUMENT_DISPATCHES__DETAIL__PANEL_TITLE__DATA_BOX_ATTRIBUTES"
          defaultMessage="Rozšiřující atributy DZ"
        />
      }
      expandable={false}
    >
      <FormTextField
        name="dataBoxMessageId"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DATA_BOX_MESSAGE_ID"
            defaultMessage="Číslo datové zprávy"
          />
        }
        disabled={true}
      />
      <FormTextField
        name="toHands"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__TO_HANDS"
            defaultMessage="K rukám"
          />
        }
        disabled={disabled}
      />
      <FormCheckbox
        name="intoOwnHands"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__INTO_OWN_HANDS"
            defaultMessage={'Zpráva typu "Do vlastních rukou"'}
          />
        }
        disabled={disabled}
      />
      {display === 'IN_DOCUMENT' && (
        <>
          <FormTextField
            name="document.esFile.number.value"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DOCUMENT_FILE_NUMBER"
                defaultMessage="Naše spisová značka"
              />
            }
            disabled={true}
          />
          <FormTextField
            name="document.referenceNumber"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DOCUMENT_REFERENCE_NUMBER"
                defaultMessage="Naše číslo jednací"
              />
            }
            disabled={true}
          />
          <FormTextField
            name="recipient.fileNumber"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_FILE_NUMBER"
                defaultMessage="Vaše spisová značka"
              />
            }
            disabled={disabled}
          />
          <FormTextField
            name="recipient.referenceNumber"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_REFERENCE_NUMBER"
                defaultMessage="Vaše číslo jednací"
              />
            }
            disabled={disabled}
          />
        </>
      )}
      <FormTextField
        name="empowerment.number"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__EMPOWERMENT_NUMBER"
            defaultMessage="Zmocnění - číslo"
          />
        }
        disabled={disabled}
      />
      <FormTextField
        name="empowerment.year"
        type="number"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__EMPOWERMENT_YEAR"
            defaultMessage="Zmocnění - rok"
          />
        }
        disabled={disabled}
      />
      <FormTextField
        name="empowerment.sectionSign"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__EMPOWERMENT_SECTION_SIGN"
            defaultMessage="Zmocnění - paragraf"
          />
        }
        disabled={disabled}
      />
      <FormTextField
        name="empowerment.paragraph"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__EMPOWERMENT_PARAGRAPH"
            defaultMessage="Zmocnění - odstavec"
          />
        }
        disabled={disabled}
      />
      <FormTextField
        name="empowerment.letter"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__EMPOWERMENT_LETTER"
            defaultMessage="Zmocenění - písmeno"
          />
        }
        disabled={disabled}
      />
      <FormCheckbox
        name="fictionDeliveryEnabled"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__FICTION_DELIVERY_ENABLED"
            defaultMessage="Povoleno doručení fikcí"
          />
        }
        disabled={disabled}
      />
      <FormFileField
        name="deliveryNote.file"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__DELIVERY_NOTE"
            defaultMessage="Doručenka"
          />
        }
        disabled={true}
      />
    </FormPanel>
  );
}
