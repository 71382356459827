import { noop } from 'lodash';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  FormAutocomplete,
  FormCustomField,
  FormDictionaryField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  TableFieldCells,
  TextField,
  useFormSelector,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import { useParticipants } from '@modules/es-file/es-file-api';
import { useUserRoles } from '@modules/role/role-api';
import {
  useContactColumns,
  useContactFiltersFields,
} from '@modules/subject/subject-columns';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import {
  autocompleteLabelMapper,
  dictionaryFieldLabelMapper,
} from '@components/form/misc/label-mappers';
import { ViewingRequest } from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  Role,
  ViewingMethod,
} from '@enums';

export function useViewingRequestFields() {
  const intl = useIntl();

  const { viewingMethods, viewingRequestStates, participantTypes } =
    useContext(StaticDataContext);

  return function Fields() {
    const { esFile, viewingProtocol } = useFormSelector(
      (data: ViewingRequest) => ({
        esFile: data.esFile,
        viewingProtocol: data.viewingProtocol,
      })
    );

    const viewingMethodsSource = useStaticListSource(viewingMethods);
    const viewingRequestStatesSource =
      useStaticListSource(viewingRequestStates);
    const participantTypeSource = useStaticListSource(participantTypes);
    const useParticipantTypes = () => participantTypeSource;

    const elaborators = useUserRoles({
      filters: [
        {
          field: 'role.id',
          operation: ApiFilterOperation.EQ,
          value: Role.VIEWING_REQUEST_MANAGER,
        },
      ],
    });

    const participants = useParticipants({
      esFileId: esFile?.id,
      loadDetail: true,
    });
    const participantsSource = useScrollableSource({
      url: `${EvidenceApiUrl.ES_FILES}/${esFile?.id}/participant/list`,
    });
    const participantColumns = useContactColumns({
      datakeyPrefix: 'contact',
    });
    const participantFiltersFields = useContactFiltersFields({
      datakeyPrefix: 'contact',
    });

    return (
      <>
        <FormTextField
          name="esFile.number.value"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__FILE_NUMBER"
              defaultMessage="Spisová značka"
            />
          }
          after={
            esFile?.id && (
              <Redirect url={EvidenceBrowserUrl.ES_FILES_ALL} id={esFile.id} />
            )
          }
          required
          disabled
        />
        <FormLocalDateTimeField
          name="created"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__CREATED"
              defaultMessage="Datum vytvoření"
            />
          }
          disabled
        />
        <FormSelect
          name="viewingMethod"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__VIEWING_METHOD"
              defaultMessage="Způsob nahlížení"
            />
          }
          source={viewingMethodsSource}
          required
          disabled
          valueIsId
        />
        <FormTextField
          name="requester.contact.dataBox.identifier"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__DATA_BOX_ID"
              defaultMessage="Číslo datové schránky el. žádosti"
            />
          }
          disabled
        />
        <FormSelect
          source={participantTypeSource}
          name="participantTypes"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__PARTICIPANT_TYPES"
              defaultMessage="Typ účastníka na spisu"
            />
          }
          valueIsId
          multiple
          disabled
        />
        <FormDictionaryField
          name="requester"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__REQUESTER"
              defaultMessage="Žadatel o nahlížení"
            />
          }
          filters={[
            participantFiltersFields.filtersFieldFirstName,
            participantFiltersFields.filtersFieldLastName,
            participantFiltersFields.filtersFieldCompanyName,
            participantFiltersFields.filtersFieldDataBoxId,
            participantFiltersFields.filtersFieldAddress,
          ]}
          columns={[
            participantColumns.columnFirstName,
            participantColumns.columnLastName,
            participantColumns.columnCompanyName,
            participantColumns.columnAddress,
            participantColumns.columnDataBoxId,
            {
              name: intl.formatMessage({
                id: 'ES__VIEWING_REQUESTS__COLUMN__REQUESTER_TYPE',
                defaultMessage: 'Typ účastníka',
              }),
              datakey: 'type',
              sortkey: 'type.name',
              CellComponent:
                TableFieldCells.useSelectCellFactory(useParticipantTypes),
              width: 200,
            },
          ]}
          required={true}
          autocompleteSource={participants}
          dialogSource={participantsSource}
          labelMapper={dictionaryFieldLabelMapper}
          maxRows={9}
          dialogWidth={1000}
        />
        <FormAutocomplete
          name="elaborator"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__ELABORATOR"
              defaultMessage="Zpracovatel žádosti"
            />
          }
          source={elaborators}
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormSelect
          name="state"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__STATE"
              defaultMessage="Stav žádosti"
            />
          }
          source={viewingRequestStatesSource}
          disabled
          valueIsId
        />
        <FormTextArea
          name="note"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__NOTE"
              defaultMessage="Poznámka"
            />
          }
        />
        <FormCustomField
          name="viewingProtocol"
          label={
            <FormattedMessage
              id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__VIEWINIG_PROTOCOL"
              defaultMessage="Protokol o nahlížení"
            />
          }
        >
          <TextField
            value={viewingProtocol?.referenceNumber}
            onChange={noop}
            disabled
          />
          {viewingProtocol?.id && (
            <Redirect
              url={EvidenceBrowserUrl.DOCUMENTS_INTERNAL}
              id={viewingProtocol.id}
            />
          )}
        </FormCustomField>
      </>
    );
  };
}

export function PhysicalViewingDetails() {
  const { viewingMethod } = useFormSelector((data: ViewingRequest) => ({
    viewingMethod: data.viewingMethod,
  }));

  if (viewingMethod !== ViewingMethod.PHYSICAL) {
    return null;
  }

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__PHYSICAL_VIEWING"
          defaultMessage="Detaily fyzického nahlížení"
        />
      }
      expandable={false}
    >
      <FormTextField
        name="physicalViewing.startToken"
        label={
          <FormattedMessage
            id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__START_TOKEN"
            defaultMessage="Token pro začátek nahlížení"
          />
        }
        disabled={true}
      />
      <FormTextField
        name="physicalViewing.username"
        label={
          <FormattedMessage
            id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__USERNAME"
            defaultMessage="Uživatelské jméno"
          />
        }
        disabled={true}
      />
      <FormTextField
        name="physicalViewing.passwordPlain"
        label={
          <FormattedMessage
            id="ES__VIEWING_REQUESTS__DETAIL__FIELD_LABEL__PASSWORD"
            defaultMessage="Heslo uživatele"
          />
        }
        disabled={true}
      />
    </FormPanel>
  );
}
