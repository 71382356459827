import React, {useContext, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SyncIcon from '@material-ui/icons/Sync';
import {
  ActionButtonHandle,
  DetailContext,
  FormAutocomplete,
  FormContext,
  FormDateField,
  FormDictionaryField,
  FormPanel,
  FormSelect,
  FormTextField,
  Sort,
  TableSort,
  useEventCallback,
  useFormSelector,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import {useCountries} from '@modules/dict-country/dict-country-api';
import {useParticipantsContacts} from '@modules/es-file/es-file-api';
import {CONTACTS_SORT, useContacts} from '@modules/subject/subject-api';
import {useContactColumns, useContactFiltersFields,} from '@modules/subject/subject-columns';
import {SubjectToolbar} from '@modules/subject/subject-toolbar';
import {VerifyDataBoxButton} from '@modules/viewing-request/dialog-actions/verify-data-box-button';
import {ContactAutocompleteItem, createRowStyle,} from '@composite/contact/conact-utils';
import {StaticDataContext} from '@components/evidence/static-data-provider/static-data-provider';
import {CityField} from '@components/form/address/city-field';
import {ZipCodeField} from '@components/form/address/zip-code-field';
import {autocompleteLabelMapper, dictionaryFieldLabelMapper,} from '@components/form/misc/label-mappers';
import {Dispatch, Subject} from '@models';
import {DispatchMethod, DispatchState, EvidenceApiUrl, RecipientSource, SubjectType,} from '@enums';

const useStyles = makeStyles({
  actionButton: {
    width: 100,
    height: 22,
    padding: '0 10px',
    marginLeft: 3,
    fontSize: '12px',
    fontWeight: 600,
    flexShrink: 0,
  },
});

export function RecipientFields() {
  const classes = useStyles();
  const participantSort = [
    { field: 'contact.subject.label', type: 'FIELD', order: 'ASC' },
    { field: 'contact.subject.id', type: 'FIELD', order: 'ASC' },
    { field: 'contact.label', type: 'FIELD', order: 'ASC' },
  ] as Sort[];

  const { subjectTypes } = useContext(StaticDataContext);
  const { setFieldValue } = useContext(FormContext);
  const { source } = useContext(DetailContext);

  const recipientsSource = useStaticListSource([
    { id: RecipientSource.DIRECTORY, name: 'Adresář' },
    { id: RecipientSource.PARTICIPANTS, name: 'Účastníci' },
  ]);
  const subjectTypesSource = useStaticListSource(subjectTypes);
  const countries = useCountries();

  const { method, subjectType, recipient, recipientSource, state } =
    useFormSelector((data: Dispatch) => ({
      method: data?.method,
      subjectType: data?.recipient?.subjectType,
      recipient: data?.recipient,
      recipientSource: data?.recipientSource,
      state: data.state,
    }));

  const createRef = useRef<ActionButtonHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const participantsContactSource = useScrollableSource({
    url: `${EvidenceApiUrl.SUBJECT_CONTACTS}/${source?.data?.esFile?.id}/participant`,
  });
  const participantsContacts = useParticipantsContacts({
    esFileId: source?.data?.esFile?.id,
    loadDetail: true,
    params: {
      sort: participantSort,
    },
  });

  const contacts = useContacts();
  const contactSource = useScrollableSource({
    url: `${EvidenceApiUrl.SUBJECT_CONTACTS}/list`,
  });

  const { contactColumns } = useContactColumns({
    labelPrefix: 'Adresát',
  });
  const { contactFiltersFields } = useContactFiltersFields({
    labelPrefix: 'Adresát',
  });

  const handleRecipientChange = useEventCallback(async (recipient) => {
    if (!recipient) {
      return;
    }

    const { subject, dataBox, email, address, label } = recipient;

    const {
      country,
      city,
      street,
      orientationNumber,
      descriptiveNumber,
      zipCode,
    } = address || {};

    const originalContact = {
      email,
      dataBoxId: dataBox?.identifier,
      country,
      city,
      street,
      orientationNumber,
      descriptiveNumber,
      zipCode,
      label,
    };

    setFieldValue('recipient', {
      contact: recipient,
      ...subject,
      subjectType: subject?.type,
      ...originalContact,
      originalContact,
    });

    if (dataBox) {
      setFieldValue('method', DispatchMethod.DATA_BOX);
    }
  });

  const participantContactFieldProps = {
    autocompleteSource: participantsContacts,
    dialogSource: participantsContactSource,
    columns: contactColumns,
    dialogSorts: [
      {
        field: 'subject.name',
        type: 'FIELD',
        order: 'ASC',
      } as TableSort,
    ],
    filters: contactFiltersFields,
  };

  const directoryRecipientFieldProps = {
    autocompleteSource: contacts,
    dialogSource: contactSource,
    columns: contactColumns,
    dialogSorts: [
      {
        field: 'subject.name',
        type: 'FIELD',
        order: 'ASC',
      } as TableSort,
    ],
    filters: contactFiltersFields,
  };

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__DOCUMENT_DISPATCHES__DETAIL__PANEL_TITLE__RECIPIENT"
          defaultMessage="Adresát"
        />
      }
      expandable={false}
    >
      <FormSelect
        name="recipientSource"
        source={recipientsSource}
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_FIRST_NAME"
            defaultMessage="Zdroj adresáta"
          />
        }
        valueIsId
        disabled={state !== DispatchState.PREPARED}
      />
      <SubjectToolbar
        createRef={createRef}
        editRef={editRef}
        fieldName="recipient.contact.subject"
        onResultValue={(subject: Subject) => {
          const originalContact = {
            email: subject.selectedContact?.email,
            dataBoxId: subject.selectedContact?.dataBox?.identifier,
            country: subject.selectedContact?.address?.country,
            city: subject.selectedContact?.address?.city,
            street: subject.selectedContact?.address?.street,
            orientationNumber:
              subject.selectedContact?.address?.orientationNumber,
            descriptiveNumber:
              subject.selectedContact?.address?.descriptiveNumber,
            zipCode: subject.selectedContact?.address?.zipCode,
            label: subject.selectedContact?.label,
          };

          setFieldValue('recipient', {
            contact: subject.selectedContact,
            ...subject,
            subjectType: subject?.type,
            ...originalContact,
            originalContact,
          });

          if (subject?.selectedContact?.dataBox) {
            setFieldValue('method', DispatchMethod.DATA_BOX);
          }
        }}
      />
      <FormDictionaryField
        name="recipient"
        label={
          <FormattedMessage
            id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_FIRST_NAME"
            defaultMessage="Adresát"
          />
        }
        required
        notifyChange={handleRecipientChange}
        labelMapper={dictionaryFieldLabelMapper}
        dialogWidth={1000}
        onCreate={() => createRef.current?.executeAction()}
        onEdit={() => editRef.current?.executeAction()}
        {...(recipientSource === RecipientSource.DIRECTORY
          ? directoryRecipientFieldProps
          : participantContactFieldProps)}
        disabled={state !== DispatchState.PREPARED}
        dialogSorts={CONTACTS_SORT}
        createRowStyle={createRowStyle}
        AutocompleteItemComponent={ContactAutocompleteItem}
      />

      {recipient && (
        <>
          <FormSelect
            name="recipient.subjectType"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_SUBJECT_TYPE"
                defaultMessage="Typ subjektu"
              />
            }
            source={subjectTypesSource}
            required={true}
            valueIsId={true}
            disabled
          />
          {subjectType === SubjectType.NATURAL_PERSON && (
            <>
              <FormTextField
                name="recipient.firstName"
                label={
                  <FormattedMessage
                    id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_FIRST_NAME"
                    defaultMessage="Jméno"
                  />
                }
                disabled
              />
              <FormTextField
                name="recipient.lastName"
                label={
                  <FormattedMessage
                    id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_LAST_NAME"
                    defaultMessage="Příjmení"
                  />
                }
                disabled
              />
              <FormDateField
                name="recipient.birthDate"
                label={
                  <FormattedMessage
                    id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_BIRTH_DATE"
                    defaultMessage="Datum narození"
                  />
                }
                disabled
              />
            </>
          )}
          {subjectType !== SubjectType.NATURAL_PERSON && (
            <>
              <FormTextField
                name="recipient.companyName"
                label={
                  <FormattedMessage
                    id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_COMPANY_NAME"
                    defaultMessage="Název firmy/organizace"
                  />
                }
                disabled
              />
              <FormTextField
                name="recipient.ico"
                label={
                  <FormattedMessage
                    id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_ICO"
                    defaultMessage="IČO"
                  />
                }
                disabled
              />
            </>
          )}
          <FormAutocomplete
            name="recipient.country"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_COUNTRY"
                defaultMessage="Země"
              />
            }
            source={countries}
            labelMapper={autocompleteLabelMapper}
            required={method === DispatchMethod.LETTER}
            disabled={state !== DispatchState.PREPARED}
          />
          <ZipCodeField
            prefix="recipient"
            disabled={state !== DispatchState.PREPARED}
          />
          <CityField
            prefix="recipient"
            required={method === DispatchMethod.LETTER}
            disabled={state !== DispatchState.PREPARED}
          />
          <FormTextField
            name="recipient.street"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_STREET"
                defaultMessage="Ulice"
              />
            }
            required={method === DispatchMethod.LETTER}
            disabled={state !== DispatchState.PREPARED}
          />
          <FormTextField
            name="recipient.orientationNumber"
            type="number"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_ORIENTATION_NUMBER"
                defaultMessage="Číslo orientační"
              />
            }
            disabled={state !== DispatchState.PREPARED}
          />
          <FormTextField
            name="recipient.descriptiveNumber"
            type="number"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_DESCRIPTIVE_NUMBER"
                defaultMessage="Číslo popisné"
              />
            }
            disabled={state !== DispatchState.PREPARED}
          />

          <FormTextField
            name="recipient.email"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_EMAIL"
                defaultMessage="Email"
              />
            }
            required={method === DispatchMethod.EMAIL}
            disabled={state !== DispatchState.PREPARED}
          />
          <FormTextField
            name="recipient.dataBoxId"
            label={
              <FormattedMessage
                id="ES__DOCUMENT_DISPATCHES__DETAIL__FIELD_LABEL__RECIPIENT_DATA_BOX_ID"
                defaultMessage="ID datové schránky"
              />
            }
            after={
              <VerifyDataBoxButton
                dataBoxId={recipient.dataBoxId}
                ButtonComponent={({ onClick }) => (
                  <Button
                    onClick={onClick}
                    variant="text"
                    startIcon={<SyncIcon />}
                    className={classes.actionButton}
                    disabled={!recipient.dataBoxId}
                  >
                    <FormattedMessage
                      id="EAS__DICTIONARY_FIELD__SEARCH_BUTTON"
                      defaultMessage="Ověřit"
                    />
                  </Button>
                )}
              />
            }
            required={method === DispatchMethod.DATA_BOX}
            disabled={state !== DispatchState.PREPARED}
          />
        </>
      )}
    </FormPanel>
  );
}
